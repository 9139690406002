/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import { defineStore } from 'pinia'
import { store } from '@/store'
import { LoginParams } from '@/api/sys/model/userModel'
import router from '@/router'
import { Toast } from 'vant'
import { loginApi } from '@/api/user'
// interface UserState {
//   token?: string;
//   sessionTimeout?: boolean;
//   lastUpdateTime: number;
//   userInfo: GetUserInfoModel;
// }
interface userInfoModer {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  sub: string;
  domainCode?: any;
  expire: string;
  ward?: any;
  postId?: any;
  depart: string;
  userName: string;
  userId: string;
  tenant: string;
  jti: string;
}
interface detailUserInfoModer {
  userId?: string;
  platformAccount?: string;
  platformName?: string;
  platformAccountName?: string;
}
interface UserState {
  token?: string;
  userInfo?: userInfoModer | null;
  detailUserInfo?: detailUserInfoModer | null;
}
export const useUserStore = defineStore({
  id: 'app-user',
  persist: true,
  state: (): UserState => ({
    // user info
    userInfo: null,
    // token
    token: undefined,
    detailUserInfo: null
    // roleList
    // Whether the login expired
    // Last fetch time
  }),
  getters: {
    getToken (): string {
      return this.token || ''
    }
  },
  actions: {
    setToken (info: string | undefined) {
      this.token = info ? 'Bearer' + ' ' + info : '' // for null or undefined value
    },
    setUserInfo (info: any) {
      this.userInfo = info
    },
    setDetailUserInfo (info: any) {
      this.detailUserInfo = info
    },
    resetState () {
      this.userInfo = null
      this.token = ''
    },
    async login (
      params: LoginParams
    ): Promise<any> {
      try {
        const data = await loginApi(params)
        this.setToken(data.access_token)
        this.setUserInfo(data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: logout
     */
    logout () {
      this.setToken(undefined)
      this.setUserInfo(null)
      router.push('login')
      Toast.success('退出成功')
    }
  }
})

// Need to be used outside the setup
export function useUserStoreWithOut () {
  return useUserStore(store)
}
