<template>
  <!-- <router-view v-slot="{ Component }">
    <keep-alive>
      <component :key="$route.name" :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :key="$route.name" :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view> -->
    <router-view></router-view>
    <!-- <h4>
      尊敬的代理。
    </h4>
    <p>
      为了给代理们提供更好的产品，更快捷的结算方式。
    </p>
    <p>
现对系统进行维护升级，升级期间暂时无法进行推广，提现等操作，预计维护时间4小时
给各位代理带来的不便尽请原谅。
    </p>
    <p>
      有问题随时联系平台或上级代理沟通解决，谢谢。
    </p> -->
</template>

<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
// p{
//   margin: 0.5rem;
//   font-size: 24px;
// }
// h4{
//   margin: 0.5rem;
//   font-size: 24px;
//   font-weight: bold;
// }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
