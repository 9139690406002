import { LoginParams, orderListParams, opsDetailUserParams, setDetailUserInfoParams, withdrawCashParams, updateCommissionParams, changePasswordParams } from './sys/model/userModel'
import { userIdModel, requestModer } from './sys/model/sysModel'
import request from '@/utils/http'

// 登录
export function loginApi (data: LoginParams): Promise<requestModer> {
  return request({
    url: '/api/auth/oauth/token',
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'application/json'
    }
  })
}
// 获取当前登录用户业务统计信息
export function businessUserInfo (params:userIdModel): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsUser/business',
    params
  })
}
// 查询代理或分销人员的订单
export function opsOrderList (params: orderListParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsOrder/pageList',
    params
  })
}
// 查询代理或分销人员的订单
export function userInfo (params: userIdModel): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsCashing/userInfo',
    params
  })
}
// 查询用户提现列表
export function opsCashList (params: orderListParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsCashing/pageList',
    params
  })
}

// 订单与奖罚明细联合查询
export function opsOrderPageList (params: orderListParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsRewardPunish/orderAndRewardPageList',
    params
  })
}

// 查询租户下面的代理或者代理下面的分销 的 个人信息
export function opsDetailUser (params: opsDetailUserParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsUser/pageList',
    params
  })
}
// 完善个人信息
export function setDetailUserInfo (data: setDetailUserInfoParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsUser/updateUserInfo',
    data,
    method: 'post'
  })
}
// 创建用户(代理或者分销人员)
export function createUser (data: setDetailUserInfoParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsUser/createUser',
    data,
    method: 'post'
  })
}
// 用户提现
export function withdrawCash (data: withdrawCashParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsCashing/submit',
    data,
    method: 'post'
  })
}
// 修改分佣连接
export function updateCommission (data: updateCommissionParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsPagelink/update',
    data,
    method: 'post'
  })
}
// 修改分佣连接
export function changePasswordAPI (params: changePasswordParams): Promise<requestModer> {
  return request({
    url: '/api/admin/user/changePassword',
    params,
    method: 'post'
  })
}
// 订单分销人员导出
export function listExport (data: orderListParams): Promise<requestModer> {
  return request({
    url: '/api/distribution/opsOrder/downOrderBySale',
    method: 'post',
    data,
    responseType: 'blob' // 表明返回服务器返回的数据类型，要加在请求头里面，否则识别不了文件流
  })
}
