
import axios from 'axios'
// import { getToken } from '@/utils/auth'
import { Toast } from 'vant'
import { useUserStore } from '@/store/modules/user'
import { requestModer } from '@/api/sys/model/sysModel'
export const request = (options:object):Promise<requestModer> => {
  return new Promise((resolve, reject) => {
    // create an axios instance
    const service = axios.create({
      // baseURL: process.env.BASE_API, // api 的 base_url
      baseURL: '',
      timeout: 80000 // request timeout
    })

    // request interceptor
    service.interceptors.request.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (config:any) => {
        const store = useUserStore()
        const token = store.getToken
        if (token) {
          config.headers.Authorization = token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
        } else {
          config.headers.Authorization = 'Basic bHl4dF9kaXN0cmlidXRpb25faDU6WkBDayQmQUNnYXdXWm16ZHAxOA==' // 增加客户端认证 deya_operation Aa12345678@ Basic ZGV5YV9vcGVyYXRpb246QWExMjM0NTY3OEA=  bHl4dF9tZXJjaGFudDpBYTEyMzQ1Njc4QA==
        }
        if (config.url === '/api/auth/oauth/token') {
          config.headers.Authorization = 'Basic bHl4dF9kaXN0cmlidXRpb25faDU6WkBDayQmQUNnYXdXWm16ZHAxOA=='
        }
        return config
      },
      error => {
        // Do something with request error
        console.log('出错啦', error) // for debug
        Promise.reject(error)
      }
    )

    // response interceptor
    service.interceptors.response.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response:any) => {
        if (response.config.url === '/api/auth/oauth/token') {
          return response.data
        }
        if (response.data.status !== '200') {
          Toast(response.data.message)
        }
        // if (!response.data.status) {
        //   const userStore = useUserStore()
        //   userStore.logout()
        //   Toast('登录信息过期')
        // }
        return response.data
      },
      error => {
        console.log('err' + error) // for debug
        if (error.response.status === 403) {
          Toast('错了')
        } else if (error.response.status === 401) {
          const userStore = useUserStore()
          userStore.logout()
          Toast('登录信息过期')
        } else {
          const message = error.response.data.message || '系统错误'
          Toast(message)
        }
        return Promise.reject(error)
      }
    )
    // 请求处理
    service(options)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default request
