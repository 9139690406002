/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import { defineStore } from 'pinia'
import { store } from '@/store'
interface UserState {
  loading?: boolean;
}
export const useSysrStore = defineStore({
  id: 'app-sys',
  state: (): UserState => ({
    loading: false
  }),
  actions: {
    setLoading (loading: boolean) {
      this.loading = loading
    }
  }
})

// Need to be used outside the setup
export function useSysStoreWithOut () {
  return useSysrStore(store)
}
