import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './lib/lib-flexible.ts'
import { setupStore } from '@/store'
import './styles/index.scss' // global css
import { Col, Row, Button, Icon, Tabbar, TabbarItem, Swipe, SwipeItem, Cell, CellGroup, Tag, Grid, GridItem, Image as VanImage, List, PullRefresh, Card, NoticeBar, Divider, Form, Field, Tab, Tabs, Toast, Dialog, Notify, DropdownMenu, DropdownItem, Calendar, Search, NumberKeyboard, Popup, SwipeCell, Checkbox, CheckboxGroup, Sticky, Picker } from 'vant'
// import Vconsole from 'vconsole' // 引入pinia
import loading from './directive/loading'
const app = createApp(App)
// app.use(pinia)
app.directive('loading', loading)
setupStore(app)
app.use(router)
app.use(Icon)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Swipe)
app.use(SwipeItem)
app.use(Cell)
app.use(CellGroup)
app.use(Tag)
app.use(Grid)
app.use(GridItem)
app.use(VanImage)
app.use(List)
app.use(PullRefresh)
app.use(Card)
app.use(Button)
app.use(NoticeBar)
app.use(Divider)
app.use(Form)
app.use(Field)
app.use(Tab)
app.use(Tabs)
app.use(Toast)
app.use(Dialog)
app.use(Notify)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Calendar)
app.use(Search)
app.use(NumberKeyboard)
app.use(Popup)
app.use(Col)
app.use(Row)
app.use(SwipeCell)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Sticky)
app.use(Picker)
// if (process.env.NODE_ENV !== 'production') {
//   const vConsole = new Vconsole()
//   console.log(vConsole.version)
// }
app.mount('#app')
